.Blob {
    text-align: center;
    align-items: center;
  }
  
  .Blob-header {
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    font-weight: bolder;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  
  .Blob-list {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    margin: auto;
    width: 90%
  }